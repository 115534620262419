<template>
      <v-row class="ma-0 h-full pa-0 my-3 mx-10 align-start justify-center page-container background">
        <!-- If using vue-router -->
        <transition mode="out-in">
          <router-view />
        </transition>
      </v-row>
</template>

<script>
export default {

}
</script>

<style>
.page-container:first-child {
  width: 100% !important;
}
</style>